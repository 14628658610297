import React, { useState } from "react"
import { Seo } from "../components/Seo"
import { DefaultLayout } from "../layout/DefaultLayout"
import styled from "@emotion/styled"
import { fonts, colors, device } from "../components/layout/GlobalStyles"
import { Button } from "../components/layout/StyledComponents"
import { Link } from "gatsby"

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 72px 0px 128px;

  background: ${colors.secondary201};
  input[type="checkbox"] {
    accent-color: ${colors.primary2};
  }
  .line {
    height: 2px;
    background: ${colors.primary2};
    width: 100%;
    margin: 32px 0px 72px;
  }
  .sub-header {
    margin-bottom: 84px;
  }
  button {
    position: absolute;
    right: 0em;
    margin-top: 22px;
  }
  .option-container {
    margin-top: 46px;
    p {
      margin-bottom: 32px;
    }
  }
  .top-field-container {
    display: flex;
    flex-direction: column;
  }

  fieldset {
    border: none;
    padding: 0;
    margin-top: 40px;

    input[type="checkbox"] {
      -ms-transform: scale(2.8); /* IE 9 */
      -webkit-transform: scale(2.8); /* Chrome, Safari, Opera */
      transform: scale(2.8);
      margin: 0px 0px;

      display: flex;
    }

    input[type="checkbox"]:checked:before {
      border: #1c4cbd;
      border: 1px solid red;
      background-color: #1c4cbd;
    }

    .option {
      display: flex;
      margin-left: 10px;

      align-items: center;

      label {
        position: relative;
        bottom: 22px;
        margin-left: 33px;
        font-weight: 500;
        font-size: 22px;
      }
    }
  }

  .container {
    width: 800px;
    margin: 0 auto;
  }
  p {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
  }
  h1 {
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: ${colors.primary2};
    font-weight: 900;
    margin: 32px 0px;
    font-family: ${fonts.secondary};
  }
  form {
    position: relative;
    font-family: ${fonts.primary};
    padding: 0px;
    margin: 0 auto;
    border-radius: 8px;
    background: ${colors.secondary201};
    .input-container {
      position: relative;
      span {
        font-family: ${fonts.secondary};
        color: ${colors.primary2};
        font-weight: 900;
        font-size: 28px;
      }
    }
    input:first-of-type {
      margin-bottom: 45px;
    }
    .basic-input:focus {
      border-radius: 0;

      outline: none;
    }
    /* .basic-input:invalid {
      border-bottom: 2px solid #bf262c;
    } */

    /* .basic-input[required]:focus:invalid {
      border-bottom: 2px solid #bf262c;
    } */

    .top-field-label {
      font-family: ${fonts.secondary};
      color: ${colors.primary2};
      font-weight: 700;
      font-size: 22px;
    }

    .basic-input {
      font-family: ${fonts.primary};
      padding: 12px 0px 18px;
      /* margin-bottom: 3em;
      padding-left: 2em; */
      background: ${colors.secondary201};
      border: 2px solid ${colors.primary2};
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
    }
  }
  @media ${device.tablet} {
    padding: 128px 0px 190px;
    .container {
      width: 800px;
      margin: 0 auto;
    }
    p {
      font-size: 22px;
      font-weight: 400;
      letter-spacing: -0.69px;
    }
    h1 {
      font-size: 42px;
      text-align: left;
      color: ${colors.primary2};
      font-weight: 900;
      font-family: ${fonts.secondary};
    }
    form {
      font-family: ${fonts.primary};
      margin: 0 auto;
      .input-container {
        position: relative;
        span {
          font-family: ${fonts.secondary};
          color: ${colors.primary2};
          font-weight: 900;
          position: relative;
          right: 2em;
          font-size: 28px;
          bottom: 0em;
        }
      }
      .basic-input:focus {
        border-radius: 0;
        outline: none;
      }
    }
  }
  @media ${device.laptop} {
    padding: 210px 0px 210px;
    h1 {
      font-size: 60px;
    }
    fieldset {
      font-size: 22px;

      .option {
        font-size: 22px;
        label {
          font-weight: 500;
          font-size: 22px;
          color: ${colors.dark3};
        }
      }
    }
  }
`

const BecomeOneOfUs = ({ data }) => {
  const [hovered, setHovered] = useState(false)
  return (
    <>
      <Seo
        title="MLIFE - We are home. Whomever you are."
        description="We are an all-embracing, intergenerational, and intersectional faith-based congregation. Grassroots-led and community-driven."
      />
      <StyledForm>
        <>
          <div className="container" style={{}}>
            <div className="l-center-container">
              <div className="sc-dxgOiQ gDVPix">
                <h1 color="#000" className="sc-ckVGcZ czlKUj">
                  Become one of us
                </h1>
              </div>
              <div>
                <div className="sc-bdVaJa iIDDUy">
                  <div>
                    <div>
                      <p
                        style={{
                          maxWidth: "650px",

                          textAlign: "left",
                          fontSize: "22px",
                        }}
                        color="#434d5d"
                        className="sub-header sc-gPEVay kAkJcy"
                      >
                        We make it easy for you to make a difference. Add your
                        name to our growing list of leaders making change.
                      </p>
                      <div spacing="base" className="sc-kEYyzF bjObOo" />
                    </div>
                    <form
                      className="js-cm-form"
                      id="subForm"
                      action="https://www.createsend.com/t/subscribeerror?description="
                      method="post"
                      data-id="5B5E7037DA78A748374AD499497E309ECEAA1702F75AAA596D73166C0B5A49C9728E645D1949483FDD49C68852CE1B504C6CEBE62C0213CD5E454C4352FDAF43"
                    >
                      <div size="base" className="sc-jzJRlG bMslyb">
                        <div size="small" className="sc-jzJRlG liOVdz">
                          <div className="top-field-container">
                            <label
                              size="0.875rem"
                              color="#5d5d65"
                              className="top-field-label sc-gzVnrw dEVaGV"
                            >
                              <span class="placeholder">First Name </span>
                              {/* <span className="sc-dnqmqq iFTUZ">*</span> */}
                            </label>
                            <input
                              style={{
                                fontSize: "18px",
                              }}
                              aria-label="First Name"
                              id="fieldddluhjm"
                              maxLength="200"
                              name="cm-f-ddluhjm"
                              required
                              placeholder="Enter your name"
                              type="text"
                              className="basic-input sc-iwsKbI iMsgpL"
                            />
                          </div>
                        </div>

                        <div size="small" className="sc-jzJRlG liOVdz">
                          <div className="top-field-container">
                            <label
                              // size="0.875rem"
                              // color="#5d5d65"
                              className="top-field-label sc-gzVnrw dEVaGV"
                            >
                              <span class="placeholder">Email</span>
                            </label>
                            {/* //<span className=" sc-dnqmqq iFTUZ">*</span> */}

                            <input
                              style={{
                                fontSize: "18px",
                              }}
                              placeholder="Enter your email"
                              autoComplete="Email"
                              aria-label="Email"
                              id="fieldEmail"
                              maxLength="200"
                              name="cm-biriylj-biriylj"
                              required
                              type="email"
                              className="basic-input js-cm-email-input qa-input-email sc-iwsKbI iMsgpL"
                            />
                          </div>
                        </div>

                        <div
                          size="small"
                          className="option-container sc-jzJRlG liOVdz"
                        >
                          <div>
                            <label
                              style={{
                                margin: "0 0",
                                fontSize: "22px",
                                color: `${colors.dark3}`,
                              }}
                              className="sc-gzVnrw dEVaGV"
                            >
                              <span
                                style={{
                                  margin: "0",
                                  fontWeight: "900",
                                }}
                              >
                                Preferences{" "}
                              </span>
                              - I am especially interested in…{" "}
                            </label>
                            <fieldset>
                              <div>
                                <div className="option sc-bwzfXH ebeRtN">
                                  <input
                                    id={23050603}
                                    name="cm-fo-diuydkli"
                                    type="checkbox"
                                    defaultValue={23050603}
                                    className="qa-checkbox-cm-fo-diuydkli sc-bZQynM eKOoKL"
                                  />
                                  <label
                                    htmlFor={23050603}
                                    className="sc-bxivhb iHsWXX"
                                  >
                                    All news &amp; events
                                  </label>
                                </div>
                              </div>
                              <div>
                                <div className="option sc-bwzfXH ebeRtN">
                                  <input
                                    id={23050604}
                                    name="cm-fo-diuydkli"
                                    type="checkbox"
                                    defaultValue={23050604}
                                    className="qa-checkbox-cm-fo-diuydkli sc-bZQynM eKOoKL"
                                  />
                                  <label
                                    htmlFor={23050604}
                                    className="sc-bxivhb iHsWXX"
                                  >
                                    Church events
                                  </label>
                                </div>
                              </div>
                              <div>
                                <div className="option sc-bwzfXH ebeRtN">
                                  <input
                                    id={23050605}
                                    name="cm-fo-diuydkli"
                                    type="checkbox"
                                    defaultValue={23050605}
                                    className="qa-checkbox-cm-fo-diuydkli sc-bZQynM eKOoKL"
                                  />
                                  <label
                                    htmlFor={23050605}
                                    className="sc-bxivhb iHsWXX"
                                  >
                                    Church news
                                  </label>
                                </div>
                              </div>
                              <div>
                                <div className="option sc-bwzfXH ebeRtN">
                                  <input
                                    id={23050606}
                                    name="cm-fo-diuydkli"
                                    type="checkbox"
                                    defaultValue={23050606}
                                    className="qa-checkbox-cm-fo-diuydkli sc-bZQynM eKOoKL"
                                  />
                                  <label
                                    htmlFor={23050606}
                                    className="sc-bxivhb iHsWXX"
                                  >
                                    Foundation events
                                  </label>
                                </div>
                              </div>
                              <div>
                                <div className="option sc-bwzfXH ebeRtN">
                                  <input
                                    id={23050607}
                                    name="cm-fo-diuydkli"
                                    type="checkbox"
                                    defaultValue={23050607}
                                    className="qa-checkbox-cm-fo-diuydkli sc-bZQynM eKOoKL"
                                  />
                                  <label
                                    htmlFor={23050607}
                                    className="sc-bxivhb iHsWXX"
                                  >
                                    Foundation news
                                  </label>
                                </div>
                              </div>
                              <div>
                                <div className="option sc-bwzfXH ebeRtN">
                                  <input
                                    id={23050608}
                                    name="cm-fo-diuydkli"
                                    type="checkbox"
                                    defaultValue={23050608}
                                    className="qa-checkbox-cm-fo-diuydkli sc-bZQynM eKOoKL"
                                  />
                                  <label
                                    htmlFor={23050608}
                                    className="sc-bxivhb iHsWXX"
                                  >
                                    Programs
                                  </label>
                                </div>
                              </div>
                              <div>
                                <div className="line"></div>
                                <div>
                                  <div className="option sc-bwzfXH ebeRtN">
                                    <input
                                      aria-required=""
                                      id="cm-privacy-consent"
                                      name="cm-privacy-consent"
                                      type="checkbox"
                                      className="qa-checkbox-cm-privacy-consent sc-bZQynM eKOoKL"
                                    />
                                    <label
                                      size="0.875rem"
                                      color="#434d5d"
                                      htmlFor="cm-privacy-consent"
                                      className="sc-bxivhb iHsWXX"
                                    >
                                      I agree to be emailed
                                    </label>
                                  </div>
                                </div>
                                <input
                                  id="cm-privacy-consent-hidden"
                                  name="cm-privacy-consent-hidden"
                                  type="hidden"
                                  defaultValue="true"
                                />
                              </div>
                            </fieldset>
                          </div>
                        </div>
                        <div size="base" className="sc-jzJRlG bMslyb">
                          <div>
                            <div
                              spacing="x-small"
                              className="sc-kEYyzF czkECw"
                            />
                            <div size="base" className="sc-jzJRlG bMslyb"></div>
                          </div>
                        </div>
                      </div>
                      {/* <Link> */}
                      <Button
                        color="yellow"
                        type="submit"
                        className="js-cm-submit-button sc-iAyFgw efTFaG"
                      >
                        Subscribe
                      </Button>
                      {/* </Link> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </StyledForm>
    </>
  )
}

BecomeOneOfUs.Layout = DefaultLayout

export default BecomeOneOfUs
